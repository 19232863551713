<script>
import {computed, onMounted, reactive, toRefs} from 'vue';
import router from "../../router";
import {ElMessage, ElMessageBox} from "element-plus";
import {v4 as uuidv4} from 'uuid';
import {useI18n} from "vue-i18n";

export default {
  name: 'TicketSweDriveForm',
  props: {
    action: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: false,
      default: () => {
        return null;
      }
    }
  },
  computed: {
    router() {
      return router
    }
  },
  setup(props) {
    const {t} = useI18n();
    const data = reactive({
          uploadSettings: {
            accept: "image/png, image/gif, image/jpeg",
            limit: 3,
            listType: 'picture-card',
            autoUpload: true
          },
          photoDialogVisible: false,
          dialogImageUrl: '',
          formHash: uuidv4(),
          categories: [],
          elForm: null,
          formDisabled: false,
          products: [],
          totalPrice: computed(() => {
            let ret = 0;
            Object.entries(data.form.products).forEach(([key, value]) => {
              if (value > 0) {
                ret += value * data.products.find(f => f.key === key).priceWeb;
              }
            });

            return ret;
          }),
          orderItems: computed(() => {
            let ret = [];
            Object.entries(data.form.products).forEach(([key, value]) => {
              if (value > 0) {
                let product = data.products.find(f => f.key === key);
                ret.push({
                  label: product.label,
                  quantity: value,
                  totalPrice: helpers.filters.toCurrency(value * product.priceWeb, t('info.culture'))
                });
              }
            });
            return ret;
          }),
          buyButtonDisabled: computed(() => {
            //pokud je cena 0, tak je tlačítko koupit vypnuto
            if (props.settings && props.settings.disabled?.buyButtonDisabled === false) {
              return false;
            }
            return data.totalPrice <= 0;

          }),
          groups: computed(_ => {
            if (data.form.driver.category === null || data.categories.length === 0) {
              return [];
            }
            return data.categories.find(category => category.id === data.form.driver.category).groups;
          }),
          formFilled: {
            actionGuid: props.action.guid,
            email: 'sivin@atlas.cz',
            street: 'Střelnice 2287',
            city: 'Česká Lípa',
            zip: '47001',
            phone: '+737 330 704',
            fullName: 'Milan Sivák',
            car: 'Volvo V60 D6 D82PHEV',
            count: 1,
            company: null,
            ico: null,
            dic: null,
            products: {
              basic: 0,
              skill: 0,
              offroad: 0,
              driver: 0,
              parkingIn: 0,
              parkingOut: 0,
              vip: 0,
              dog: 0,
              kids: 0,
              car: 0
            },
            driver: {
              category: 1,
              group: 16,
              birthDate: '1987-01-27',
              driverId: 'EK 780325',
              carModel: 'V60',
              carYear: 2015,
              carEngine: 'D6 D82PHEV',
              carEnginePower: '2400 ccm',
              carGearbox: 'Automat 6° TF80SC',
              carColorType: null,
              carAwd: 'Ano, hybrid elektro',
              carSpecial: 'D5T5 Tuning, 19" alu, 4x4, 2.4D',
              carInterest: null,
              carMileage: '150 000 Km',
              carUserHowLong: 'Od prosince roku 2023',
              carOwnerCount: '4.',
              carEstimatedValue: '500 000 Kč',
              sharePhotosAgreement: false,
              personalDataAgreement: true,
              trackRulesAgreement: true,
              photo1: [],
              photo2: [],
              carBrand: null
            }
          },
          form: {
            actionGuid: props.action.guid,
            ticketCode: null,
            email: null,
            street: null,
            city: null,
            zip: null,
            phone: null,
            fullName: null,
            car: null,
            count: 1,
            company: null,
            ico: null,
            dic: null,
            products: {
              basic: 0,
              skill: 0,
              offroad: 0,
              driver: 0,
              parkingIn: 0,
              parkingOut: 0,
              vip: 0,
              dog: 0,
              kids: 0,
              car: 0
            },
            driver: {
              category: 1,
              group: null,
              birthDate: null,
              driverId: null,
              carModel: null,
              carYear: null,
              carEngine: null,
              carEnginePower: null,
              carGearbox: null,
              carColorType: null,
              carAwd: null,
              carSpecial: null,
              carInterest: null,
              carMileage: null,
              carUserHowLong: null,
              carOwnerCount: null,
              carEstimatedValue: null,
              sharePhotosAgreement: false,
              personalDataAgreement: false,
              trackRulesAgreement: false,
              photo1: [],
              photo2: [],
              carBrand: null
            }
          }
        }
    );

    function postCreateOrder() {
      let postData = {
        action: 'createOrder',
        form: JSON.stringify(data.form)
      };
      //pokud vse dopadne ok, je poslana nova location, jinak je vypsan error
      data.formDisabled = true;
      window.helpers.server.fetchPost(router.front.xhr.order, postData)
          .finally(_ => {
            data.formDisabled = false;
          });
    }

    function postCreateDriver() {
      data.form.driver.carBrand = data.form.driver.group === 1 ? 'Volvo' : 'Saab';

      let postData = {
        action: 'createDriver',
        form: JSON.stringify(data.form)
      };
      //pokud vse dopadne ok, je poslana nova location, jinak je vypsan error
      data.formDisabled = true;
      window.helpers.server.fetchPost(router.registration.xhr.driverForm(data.formHash), postData)
          .finally(_ => {
            data.formDisabled = false;
          });
    }

    function submit() {
      data.formDisabled = true;
      data.elForm.validate((valid) => {
        if (valid) {
          // ElMessageBox.alert(t('lang.preSaleClosed'), '', {
          //   confirmButtonText: 'OK'
          // });
          // return;

          //pokud v objednavce neni zadny driver, tak se jedna o ticket
          if (data.form.products.driver === 0) {
            postCreateOrder();
          }
          //pokud je v objednavce driver, tak se jedna o driver
          else if (data.form.products.driver > 0) {
            postCreateDriver();
          } else {
            ElMessage.error(t('lang.unknownFormType'));
          }
        } else {
          ElMessage.error(t('lang.formRequire'));
        }

        data.formDisabled = false;
      });
    }

    function rulesForProduct(product) {
      if (product.key === 'kids'
          || product.key === 'offroad'
          || product.key === 'dog'
          || product.key === 'parkingIn'
          || product.key === 'parkingOut'
          || product.key === 'skill'
          || product.key === 'driver') {
        return [
          {
            required: true, message: t('lang.minimumTicketRequirement'), validator: _ => {
              return data.form.products[product.key] === 0 || data.form.products.basic > 0 || data.form.products.car > 0 || data.form.products.vip > 0;
            }
          }
        ];
      }
      return [];
    }

    function loadProducts() {
      window.helpers.server.fetchPost(router.front.xhr.product, {action: 'list'})
          .then(products => {
            data.products = products;
            if (props.settings) {

              data.products.forEach(product => {
                if (props.settings.prices) {
                  let price = props.settings.prices.find(f => f.key === product.key);
                  if (price) {
                    product.priceWeb = price.priceWeb;
                  }
                }

                if (props.settings.disabled
                    && props.settings.disabled.products) {
                  let found = props.settings.disabled.products.find(f => f.key === product.key);
                  if (found) {
                    product.isDisabled = true;
                  }
                }
              });

              if (props.settings.products) {
                props.settings.products.forEach(product => {
                  data.form.products[product.key] = (data.form.products[product.key] || 0) + product.count;
                });
              }

              if (props.settings.ticketCode) {
                data.form.ticketCode = props.settings.ticketCode;
              }
            }

          });
    }

    function loadCategories() {
      let postData = {action: 'categories', actionGuid: props.action.guid};
      window.helpers.server.fetchPost(router.registration.xhr.driver, postData)
          .then(categories => {
            data.categories = categories;
          })
    }

    onMounted(_ => {
      loadProducts();
      loadCategories();
    });

    function handlePhotoPreview(uploadFile) {
      data.dialogImageUrl = uploadFile.url;
      data.photoDialogVisible = true;
    }

    function handlePictureRemove(file) {
      window.helpers.server.fetchPost(router.registration.xhr.driverPhotoRemove(data.formHash, file.response.id), {})
    }

    function handleBeforeUploadPhoto() {
      data.formDisabled = true;
    }

    function handleSuccessUploadPhoto() {
      data.formDisabled = false;
    }

    function handleBeforeRemove() {
      return ElMessageBox.confirm(t('lang.confirmRemovePhoto')).then(
          () => true,
          () => false
      );
    }

    return {
      ...toRefs(data),
      submit, toCurrency: helpers.filters.toCurrency, rulesForProduct,
      handlePhotoPreview,
      handlePictureRemove,
      handleBeforeUploadPhoto,
      handleSuccessUploadPhoto,
      handleBeforeRemove
    }
  }
}
</script>

<template>
  <div class="ticket-swe-drive-form">
    <el-dialog v-model="photoDialogVisible" width="95%">
      <div class="d-flex justify-content-center">
        <img class="img-fluid" :src="dialogImageUrl" :alt="$t('lang.imagePreview')"/>
      </div>
    </el-dialog>

    <el-form :disabled="formDisabled"
             ref="elForm"
             :model="form"
             label-width="0"
             @submit.native.prevent="submit">

      <div class="mb-5">
            <el-form-item v-for="product in products.filter(f => (f.type === 'driver' || f.type === 'ticket') && f.isDisabled === false).sort((a, b) => a.position - b.position)"
                          :key="product.id"
                          class="row p-2 bg-white border rounded mb-2"
                          :rules="rulesForProduct(product)"
                          :prop="'products.' + product.key">
              <div class="col-12 col-md-7 col-lg-8 col-xl-9 mt-1">
                <h4>{{ product.label }}</h4>
                <ul class="list-group list-group-flush">
                  <li v-for="benefit in product.benefits" class="list-group-item">{{ benefit }}</li>
                </ul>
              </div>
              <div class="col-12 col-md-5 col-lg-4 col-xl-3 py-sm-2 p-md-3 border-md-start">
                <div class="d-flex flex-column">
                  <h4 class="mb-1">{{ toCurrency(product.priceWeb, $t('info.culture')) }}</h4>
                  <div>{{ $t('lang.onSite') }}: <span class="text-danger">{{ toCurrency(product.priceInPlace, $t('info.culture')) }}</span></div>
                </div>
                <h6 class="text-success">{{ $t('lang.available') }}: {{ product.availableString }}</h6>
                <div class="d-flex flex-column mt-4">
                  <el-input-number v-model="form.products[product.key]" :min="product.min" :max="product.max" size="large" :disabled="product.isDisabled"></el-input-number>
                </div>
              </div>
            </el-form-item>
      </div>

      <template v-if="form.products.driver > 0">
        <el-divider content-position="left">{{ $t('lang.registrationDetails') }}</el-divider>

        <el-form-item label-width="200px"
                      class="flex-column flex-md-row"
                      :label="$t('lang.class')"
                      prop="driver.group"
                      :rules="[ { required: true, message: $t('lang.selectClass') }]">
          <el-radio-group class="radio-group" v-model="form.driver.group" size="large">
            <el-radio-button v-for="group in groups" :key="group.id" :label="group.id">{{ group.label }}</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label-width="200px" :label="$t('lang.dateOfBirth')" prop="driver.birthDate" class="flex-column flex-md-row" :rules="[
        { required: true, message: $t('lang.fillInDateOfBirth') }
      ]">
          <el-date-picker
              :format="$t('info.luxonDateFormat')"
              v-model="form.driver.birthDate"
              type="date"
              value-format="YYYY-MM-DD"
              placeholder="13. 5. 1989"
              :disabledDate="(time) => {
              return time.getTime() > Date.now();
          }"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label-width="200px" class="flex-column flex-md-row" :label="$t('lang.driverLicense')" prop="driver.driverId" :rules="[
        { required: true, message: $t('lang.fillInDriverLicenseNumber') }
      ]">
          <el-input v-model="form.driver.driverId" placeholder="EK 780325" :maxlength="30" show-word-limit></el-input>
        </el-form-item>

        <el-divider content-position="left">{{ $t('lang.aboutVehicle') }}</el-divider>

        <el-form-item label-width="200px" class="flex-column flex-md-row" :label="$t('lang.model')" prop="driver.carModel" :rules="[
        { required: true, message: $t('lang.fillInModel') }
      ]">
          <el-input v-model="form.driver.carModel" placeholder="V70, V90CC, XC60, 850, P1800" :maxlength="40" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label-width="200px" class="flex-column flex-md-row" :label="$t('lang.yearOfManufacture')" prop="driver.carYear" :rules="[
        { required: true, message: $t('lang.fillInYearOfProduction') }
      ]">
          <el-input v-model="form.driver.carYear" placeholder="1969, 2004" :maxlength="20" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label-width="200px" class="flex-column flex-md-row" :label="$t('lang.engine')" prop="driver.carEngine" :rules="[
        { required: true, message: $t('lang.fillInEngine') }
      ]">
          <el-input v-model="form.driver.carEngine" placeholder="D6 D82PHEV " :maxlength="50" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label-width="200px" class="flex-column flex-md-row" :label="$t('lang.engineVolume')" prop="driver.carEnginePower" :rules="[
        { required: true, message: $t('lang.fillInEngineCapacity') }
      ]">
          <el-input v-model="form.driver.carEnginePower" placeholder="2400 ccm" :maxlength="20" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label-width="200px" class="flex-column flex-md-row" :label="$t('lang.transmission')" prop="driver.carGearbox" :rules="[
        { required: true, message: $t('lang.fillInTransmission') }
      ]">
          <el-input v-model="form.driver.carGearbox" placeholder="Automat 6° TF80SC" :maxlength="20" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label-width="200px" class="flex-column flex-md-row" :label="$t('lang.colorType')" prop="driver.carColorType">
          <el-input v-model="form.driver.carColorType" placeholder="Rebel blue" :maxlength="20" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label-width="200px" class="flex-column flex-md-row" :label="$t('lang.awd')" prop="driver.carAwd" :rules="[
        { required: true, message: $t('lang.fillInAWD') }
      ]">
          <el-input v-model="form.driver.carAwd" :placeholder="$t('lang.placeholderYesNoFunctional')" :maxlength="20" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label-width="200px" class="flex-column flex-md-row" :label="$t('lang.mileage')" prop="driver.carMileage" :rules="[
        { required: true, message: $t('lang.fillInMileage') }
      ]">
          <el-input v-model="form.driver.carMileage" placeholder="105 000 Km" :maxlength="20" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label-width="200px" class="flex-column flex-md-row" :label="$t('lang.ownVehicle')" prop="driver.carUserHowLong" :rules="[
        { required: true, message: $t('lang.fillInOwnershipDuration') }
      ]">
          <el-input v-model="form.driver.carUserHowLong" :placeholder="$t('lang.placeholderSinceDecember')" :maxlength="50" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label-width="200px" class="flex-column flex-md-row" :label="$t('lang.numberOfOwners')" prop="driver.carOwnerCount" :rules="[
        { required: true, message: $t('lang.fillInNumberOfOwners') }
      ]">
          <el-input v-model="form.driver.carOwnerCount" :placeholder="$t('lang.placeholderNotSure')" :maxlength="50" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label-width="200px" class="flex-column flex-md-row" :label="$t('lang.estimatedValue')" prop="driver.carEstimatedValue">
          <el-input v-model="form.driver.carEstimatedValue" :placeholder="$t('lang.placeholderAmount')" :maxlength="50" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label-width="200px" class="flex-column flex-md-row" :label="$t('lang.specialModifications')" prop="driver.carSpecial">
          <el-input v-model="form.driver.carSpecial" :placeholder="$t('lang.placeholderModifications')" type="textarea" :maxlength="200" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label-width="200px" class="flex-column flex-md-row" :label="$t('lang.vehicleInteresting')" prop="driver.carInterest">
          <el-input v-model="form.driver.carInterest" :placeholder="$t('lang.placeholderSpecial')" type="textarea" :maxlength="250" show-word-limit></el-input>
        </el-form-item>

        <el-divider content-position="left">{{ $t('lang.carPhotos') }}</el-divider>

        <img src="./images/photo1.svg" class="img-fluid" :alt="$t('lang.photographLeftSide')">

        <el-form-item label-width="200px"
                      :label="$t('lang.frontSide')"
                      class="flex-column flex-md-row"
                      prop="driver.photo1"
                      :rules="[
        { required: true, message: $t('lang.selectCarPhoto') }
      ]">
          <el-upload
              :accept="uploadSettings.accept"
              :limit="uploadSettings.limit"
              v-model:file-list="form.driver.photo1"
              :list-type="uploadSettings.listType"
              :auto-upload="uploadSettings.autoUpload"
              :on-preview="handlePhotoPreview"
              :before-remove="handleBeforeRemove"
              :on-remove="handlePictureRemove"
              :before-upload="handleBeforeUploadPhoto"
              :on-success="handleSuccessUploadPhoto"
              :action="router.registration.xhr.driverPhoto(formHash, 'front')"
          >
            <el-icon class="d-flex flex-column gap-1">
              <em class="fal fa-plus"></em>
              <span class="small">{{ $t('lang.addPhoto') }}</span>
            </el-icon>
          </el-upload>
        </el-form-item>

        <el-divider></el-divider>

        <img src="./images/photo2.svg" class="img-fluid" :alt="$t('lang.photographRightSide')">

        <el-form-item label-width="200px"
                      :label="$t('lang.rearSide')"
                      class="flex-column flex-md-row"
                      prop="driver.photo2"
                      :rules="[
        { required: true, message: $t('lang.selectCarPhoto') }
      ]">
          <el-upload
              :accept="uploadSettings.accept"
              :limit="uploadSettings.limit"
              v-model:file-list="form.driver.photo2"
              :list-type="uploadSettings.listType"
              :auto-upload="uploadSettings.autoUpload"
              :on-preview="handlePhotoPreview"
              :before-remove="handleBeforeRemove"
              :on-remove="handlePictureRemove"
              :before-upload="handleBeforeUploadPhoto"
              :on-success="handleSuccessUploadPhoto"
              :action="router.registration.xhr.driverPhoto(formHash, 'back')"
          >
            <el-icon class="d-flex flex-column gap-1">
              <em class="fal fa-plus"></em>
              <span class="small">{{ $t('lang.addPhoto') }}</span>
            </el-icon>
          </el-upload>
        </el-form-item>

        <el-form-item prop="driver.personalDataAgreement" :rules="[
        { required: true, message: $t('lang.iAgreeToProcessingPersonalData'), type: 'enum', enum: [true] }
      ]">
          <el-checkbox v-model="form.driver.personalDataAgreement" :label="$t('lang.iAgreeToPublishPhotosOnSocialMedia')"></el-checkbox>
        </el-form-item>

        <el-form-item prop="driver.sharePhotosAgreement">
          <el-checkbox v-model="form.driver.sharePhotosAgreement" :label="$t('lang.fillInConsentWithCircuitRules')"></el-checkbox>
        </el-form-item>

        <el-form-item prop="driver.trackRulesAgreement" :rules="[
        { required: true, message: $t('lang.iAgreeToCircuitRules'), type: 'enum', enum: [true] }
      ]">
          <el-checkbox v-model="form.driver.trackRulesAgreement" :label="$t('lang.billingDetails')"></el-checkbox>
        </el-form-item>

      </template>

      <el-divider content-position="left">{{ $t('lang.billingDetails') }}</el-divider>

      <el-form-item label-width="200px" :label="$t('lang.fullName')" prop="fullName" class="flex-column flex-md-row" :rules="[
          { required: true, message: $t('lang.fillInYourFullName') } ]">
        <el-input v-model="form.fullName" :placeholder="$t('lang.placeholderFullName')" :maxlength="255" show-word-limit/>
      </el-form-item>

      <el-form-item label-width="200px"
                    class="flex-column flex-md-row"
                    :label="$t('lang.street')"
                    prop="street"
                    :rules="[
        { required: true, message: $t('lang.fillInStreetAndNumber') }
      ]">
        <el-input v-model="form.street" :placeholder="$t('lang.placeholderAddress')" :maxlength="100" show-word-limit></el-input>
      </el-form-item>

      <el-form-item label-width="200px" class="flex-column flex-md-row" :label="$t('lang.city')" prop="city" :rules="[
        { required: true, message: $t('lang.fillInCity') }
      ]">
        <el-input v-model="form.city" :placeholder="$t('lang.placeholderCity')" :maxlength="100" show-word-limit></el-input>
      </el-form-item>

      <el-form-item label-width="200px"
                    class="flex-column flex-md-row"
                    :label="$t('lang.zipCode')"
                    prop="zip"
                    :rules="[
        { required: true, message: $t('lang.fillInPostalCode') }
      ]">
        <el-input v-model="form.zip" placeholder="470 01" :maxlength="100" show-word-limit></el-input>
      </el-form-item>

      <el-form-item label-width="200px" :label="$t('lang.email')" prop="email" class="flex-column flex-md-row" :rules="[ { required: true, message: $t('lang.pleaseFillInYourEmail') } ]">
        <el-input v-model="form.email" type="email" :placeholder="$t('lang.placeholderEmail')" :maxlength="200" show-word-limit/>
      </el-form-item>

      <el-form-item label-width="200px" :label="$t('lang.phone')" prop="phone" class="flex-column flex-md-row" :rules="[ { required: true, message: $t('lang.pleaseFillInYourPhone') } ]">
        <el-input v-model="form.phone" type="phone" :placeholder="$t('lang.placeholderPhone')" :maxlength="50" show-word-limit/>
      </el-form-item>

      <el-form-item label-width="200px" :label="$t('lang.theCarYouWillArriveIn')" prop="car" class="flex-column flex-md-row" :rules="[ { required: true, message: $t('lang.pleaseFillInTheCarYouWillArriveIn') } ]">
        <el-input v-model="form.car" placeholder="Volvo V70 2007, V90 CC 2023, V60 D6 2015..." :maxlength="200" show-word-limit/>
      </el-form-item>

      <el-form-item label-width="200px" :label="$t('lang.howManyOfYouWillCome')" prop="count" class="flex-column flex-md-row" :rules="[ { required: true, message: $t('lang.pleaseFillInHowManyOfYouWillAttend') } ]">
        <el-input-number v-model="form.count" :min="1" :max="10"/>
      </el-form-item>

      <el-form-item label-width="200px" :label="$t('lang.company')" prop="company" class="flex-column flex-md-row">
        <el-input v-model="form.company" :placeholder="$t('lang.placeholderCompany')" :maxlength="100" show-word-limit/>
      </el-form-item>

      <el-form-item label-width="200px" :label="$t('lang.companyId')" prop="ico" class="flex-column flex-md-row">
        <el-input v-model="form.ico" placeholder="172258745" :maxlength="50" show-word-limit/>
      </el-form-item>

      <el-form-item label-width="200px"
                    :label="$t('lang.vatNumber')"
                    prop="dic"
                    class="flex-column flex-md-row">
        <el-input v-model="form.dic" placeholder="CZ172258745" :maxlength="50" show-word-limit/>
      </el-form-item>

      <template v-if="orderItems.length > 0">
        <div>
          <h4>{{ $t('lang.yourOrder') }}</h4>
          <table class="table">
            <thead>
            <tr>
              <th scope="col">{{ $t('lang.item') }}</th>
              <th scope="col">{{ $t('lang.quantity') }}</th>
              <th scope="col">{{ $t('lang.total') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(orderItem, idx) in orderItems" :key="idx">
              <td>{{ orderItem.label }}</td>
              <td>{{ orderItem.quantity }}x</td>
              <td>{{ orderItem.totalPrice }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>

      <div class="d-flex justify-content-end">
        <h4><span class="text-muted">{{ $t('lang.totalPrice') }}:</span> <strong>{{ toCurrency(totalPrice, $t('info.culture')) }}</strong></h4>
      </div>

      <div class="d-flex justify-content-end">
        <el-button type="primary" size="large" native-type="submit" :disabled="buyButtonDisabled">{{ $t('lang.buy') }}</el-button>
      </div>

    </el-form>
  </div>
</template>

<style lang="scss">
.ticket-swe-drive-form {
  .el-form-item {
    margin-bottom: 1.5rem;
  }

  .el-form-item__label {
    font-weight: bold;
    font-size: 1rem;
  }

  .el-checkbox__label {
    font-size: 1rem;
  }

  .el-button--large {
    font-size: 1.2rem;
  }

  .el-form-item__error {
    font-size: 0.8rem;
    top: inherit;
    position: relative;
    background-color: #f56c6c;
    color: white;
    padding: 0.5rem;
  }

  .el-form-item__label {
    display: block;
    margin-left: 0.5rem;
  }

  @media (min-width: 768px) {
    .el-form-item__label {
      display: inherit;
      margin-left: 0;
    }
  }

}
</style>
<style scoped>

h5 {
  font-weight: 400
}
</style>